import React, { useState } from 'react';
import Modal from 'react-modal';
import './CookieConsentBar.css';
import ButtonComponent from '../../atom/ButtonComponent/ButtonComponent';
import PrivacyModalComponent from '../../molecule/PrivacyModalComponent/PrivacyModalComponent';

const CookieConsentBar = () => {
    const [isVisible, setIsVisible] = useState(() => {
        const isConsentGiven = localStorage.getItem('cookieConsentGiven');
        return isConsentGiven !== 'true';
    });
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const handleClose = () => {
        localStorage.setItem('cookieConsentGiven', 'true');
        setIsVisible(false);
    };

    const handleAccept = () => {
        localStorage.setItem('cookieConsentGiven', 'true');
        setIsVisible(false);
    };

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handlePreferencesConfirm = (preferences) => {
        console.log('Confirmed preferences:', preferences);
        localStorage.setItem('cookieConsentGiven', 'true');
        setIsVisible(false);
        setModalIsOpen(false);
    };

    const handlePreferencesReject = (preferences) => {
        console.log('Rejected preferences:', preferences);
        localStorage.setItem('cookieConsentGiven', 'true');
        setIsVisible(false);
        setModalIsOpen(false);
    };

    if (!isVisible) {
        return null;
    }

    const barStyle = modalIsOpen ? { backgroundColor: 'transparent' } : {};

    return (
        <div className="cookie-consent-bar" style={barStyle}>
            {!modalIsOpen && (
                <>
                    <div className="message">
                        <p>
                            By clicking "Accept All Cookies," you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.
                        </p>
                    </div>
                    <div className="buttons">
                        <div className="close-button" onClick={handleClose}>X</div>
                        <ButtonComponent size="small" onClick={handleAccept}>Accept All Cookies</ButtonComponent>
                        <ButtonComponent size="small" onClick={handleClose}>Reject All</ButtonComponent>
                        <ButtonComponent size="small" variant="outlined" onClick={openModal}>Privacy Settings</ButtonComponent>
                    </div>
                </>
            )}

            <div className="cookies-privacy-modal">
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Privacy Preference Center"
                >
                    <PrivacyModalComponent
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        onConfirm={handlePreferencesConfirm}
                        onReject={handlePreferencesReject}
                    />
                </Modal>
            </div>
        </div>
    );
};

export default CookieConsentBar;
