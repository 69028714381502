import TableTennis from '../../assets/icons/tabletennis.png';
import Scoring from '../../assets/icons/sports-scoring.jpeg';
import RealTime from '../../assets/icons/back-in-time.png';
import Intuitive from '../../assets/icons/edition.png';
import Engagement from '../../assets/icons/social-media.png';

export const ReleaseItems = [
    {
      img: TableTennis,
      heading: "SportsTalk for Table Tennis Launched in Canada",
      subheading: "Unleash the Power of SportsTalk in the World of Table Tennis",
      text: "As of February 1st, 2024, SportsTalk has officially expanded its horizons to embrace the dynamic world of table tennis in Canada! Table tennis enthusiasts across the country can now connect, compete, and celebrate the love for the sport on our platform. Whether you're a seasoned player or just starting your table tennis journey, SportsTalk is your go-to destination for all things ping pong. Join the community, find players, and elevate your table tennis experience with SportsTalk!",
      canLearnMore: false,
    },
    {
        img: Scoring,
        heading: "AI-Based Scoreboard Integration",
        subheading: "Transforming Live Streams, Elevating Viewer Experience",
        text: "We're excited to announce the integration of an AI-based scoreboard into our live streaming feature, taking your viewing experience to a whole new level! Now, viewers can enjoy real-time game scores, statistics, and insights seamlessly integrated into the live stream. Witness every point, follow the action, and stay engaged with the game like never before. The AI-based scoreboard is designed to enhance the excitement and immersion for fans, making every match a thrilling experience.",
        canLearnMore: true,
        learnMorePath: '/releases/ai-scoreboard-integration',
      },
]

export const scoreboardFeatureCards = [
  {
    img: RealTime,
    heading: 'Real-Time Updates',
    subheading: 'Instant Scoreboard Updates',
    text: 'Experience live scores, player rankings, and game statistics updated instantly.',
    canShare: false,
    canLearnMore: false,
    cardsPerRow: 3,
  },
  {
    img: Intuitive, 
    heading: 'Intuitive Design',
    subheading: 'Seamless Viewing Experience',
    text: 'A scoreboard integrated into the live stream interface, ensuring you never miss a moment.',
    canShare: false,
    canLearnMore: false,
    cardsPerRow: 3,
  },
  {
    img: Engagement,
    heading: 'Enhanced Engagement',
    subheading: 'Interactive and Informative',
    text: 'Dive deeper into the game with player profiles, historical statistics, and interactive elements.',
    canShare: false,
    canLearnMore: false,
    cardsPerRow: 3,
  },
];