export const styles = {
    twoPerRow: {
      width: '45%',
      margin: '10px',
      // other styles...
    },
    threePerRow: {
      flex: '0 1 calc(31% - 20px)',
      margin: '10px',
      // other styles...
    }
  };