import React from 'react';
import './CompanyAboutUs.css';
import PostingLayout from '../../layouts/PostingLayout/PostingLayout';
import CardComponent from '../../components/molecule/CardComponent/CardComponent';
import { CardContent } from './constants';
import Toronto from '../../assets/icons/Toronto.jpeg';
import SportCommunity from '../../assets/icons/tennis-community.jpeg';
import Experience from '../../assets/icons/tennis-instructor.jpeg';

function CompanyAboutUs() {
  return (
    <PostingLayout
      hasBackButton={false}
      subheader="About EdgeJumps"
      header="Discover Our Story"
      description="Dive into the world of EdgeJumps, where innovation meets excellence. Learn about our journey, our values, and the passionate team behind our success. Get to know how we're shaping the future with our cutting-edge solutions."
    >
      <div className="company-about-us">
        <div className="company-about-us-intro">
          <h1>Welcome to our startup venture!</h1>
          <p>
            Based in the vibrant city of Toronto, we are a passionate group of individuals committed to transforming the sports experience for enthusiasts like you. At the core of our journey is the belief that sports should be more than just a game; it should be an immersive, engaging, and enjoyable experience that brings people together!
          </p>
        </div>
        <div className="company-about-us-cards">
            {CardContent.map((card, index) => (
              <CardComponent
                key={index}
                img={card.img}
                heading={card.heading}
                subheading={card.subheading}
                text={card.text}
                canShare={card.canShare}
                canLearnMore={card.canLearnMore}
                cardsPerRow={3}
              />
            ))}
        </div>
        <div className="about-us-section">
          <div className="content">
          <p>Born in the diverse and dynamic city of Toronto, our startup embodies the energy and spirit of one of the world's most sports-loving communities. Toronto is not just our headquarters; it's our inspiration—a city that thrives on the pulse of sports, and where every cheering fan contributes to the rich tapestry of athletic fervor.</p>
          </div>
          <div className="image">
            <img src={Toronto} alt="Toronto" />
          </div>
        </div>

        <div className="about-us-section">
          <div className="image">
            <img src={SportCommunity} alt="Sports Experience" />
          </div>
          <div className="content">
            <p>What drives us is our unbridled passion for sports and the desire to provide enthusiasts with a better, more enriching experience. We understand the joy, the camaraderie, and the sheer thrill that sports bring, and we are dedicated to enhancing these moments for every sports lover.</p>
          </div>
        </div>

        <div className="about-us-section">
          <div className="content">
            <p>We invite you to join us on this exciting journey of redefining sports experiences. Whether you're a casual player, a dedicated athlete, or a sports enthusiast cheering from the sidelines, you are a valued member of our community.</p>
          </div>
          <div className="image">
            <img src={Experience} alt="Join Us" />
          </div>
        </div>

      </div>
    </PostingLayout>
  );
}

export default CompanyAboutUs;
