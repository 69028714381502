import React from 'react';
import './NavigationComponent.css';
import TopBarDropdownComponent from '../../atom/TopBarDropdownComponent/TopBarDropdownComponent';
import TopBarLinkComponent from '../../atom/TopBarLinkComponent/TopBarLinkComponent';

const NavigationComponent = () => {
  return (
    <div className="navigation-component">
      {/* <TopBarDropdownComponent 
        label="Why SportsTalk" 
        links={[
          { url: '/why-choose-us', label: 'Why Choose SportsTalk', description: 'Description for Link 1' }, 
          { url: '/comparisons', label: 'Comparison with Competitors', description: 'Description for Link 2' },
          { url: '/customers', label: 'Customer Success Stories', description: 'Description for Link 3' },
        ]} 
      />z */}
      <TopBarLinkComponent 
        label="Why SportsTalk"
        url="/why-choose-us"
      />
      <TopBarLinkComponent 
        label="What's New"
        url="/releases"
      />
      <TopBarDropdownComponent 
        label="Product" 
        links={[
          { url: '/meet-sportstalk', label: 'Meet SportsTalk', description: 'Discover the SportsTalk Advantage' }, 
          { url: '/individual', label: 'For Individuals', description: 'Introducing our Mobile App' },
          { url: '/sports-organization', label: 'For Sports Organizations', description: 'Introducing our Dashboard' },
          { url: '/club', label: 'For Clubs', description: 'Introducing our Community Portal' },
        ]} 
      />
      <TopBarDropdownComponent 
        label="Resources" 
        links={[
          { url: 'https://www.youtube.com/@edgejumps', label: 'SportsTalk TV', description: 'Watch SportsTalk in Action on YouTube' }, 
          { url: 'https://docs.sportstalk.mobi/', label: 'Documentation', description: 'Guides and Manuals' },
          { url: '/events', label: 'Events', description: 'Upcoming SportsTalk Events' },
          { url: '/blogs', label: 'Blogs', description: 'Insights and Updates' },
          { url: 'https://kb.sportstalk.mobi', label: 'Knowledge Base', description: 'Your Questions, Answered' },
        ]} 
      />
    </div>
  );
};

export default NavigationComponent;
