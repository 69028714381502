import React, { useState } from 'react';
import './Contact.css';
import ContactIcon from '../../assets/icons/operator.png';
import ButtonComponent from '../../components/atom/ButtonComponent/ButtonComponent';

const Contact = () => {

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    organizationName: '',
    phoneNumber: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <div className="contact">
      
      <div className="contact-hero-section">
        <img src={ContactIcon} alt="Megaphone" className="contact-hero-section-megaphone-image" />
        <div className="contact-hero-section-hero-text">
          <h1>Get in touch with us</h1>
          <h3>Have a question or need to find us? We’re here to help.</h3>
          <h3>Here are all the details you’ll need.</h3>
        </div>
      </div>

      <div className="contact-content-section">
        <div className="contact-content-section-left-column">
          <p>For any general inquiries about SportsTalk, email us at <a href="mailto:info@edgejumps.com">info@edgejumps.com</a>, or send us a message using the form on this page, and we’ll make sure it gets to the right team.</p>
          <p>For product support, please email <a href="mailto:support@edgejumps.com">support@edgejumps.com</a>.</p>
          <p>And if you're interested in joining our awesome team at EdgeJumps, please email us at <a href="mailto:jobs@edgejumps.com">jobs@edgejumps.com</a>.</p>
        </div>

        <div className="contact-form-container">
        <form onSubmit={handleSubmit} className="contact-form">
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            placeholder="First Name"
            required
          />
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            placeholder="Last Name"
            required
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            required
          />
          <input
            type="text"
            name="organizationName"
            value={formData.organizationName}
            onChange={handleChange}
            placeholder="Organization Name"
          />
          <input
            type="tel"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            placeholder="Phone Number"
          />
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Send us a message"
            rows={5}
          ></textarea>
          <ButtonComponent type="submit">Contact Us</ButtonComponent>
        </form>
      </div>
      </div>
    </div>
  );
}

export default Contact;
