import React from 'react';
import { Link, useNavigate } from 'react-router-dom'; 
import './FooterComponent.css';
import BottomBarComponent from '../../molecule/BottomBarComponent/BottomBarComponent';
import CommunityPortalImage from '../../../assets/icons/CommunityPortal.png'
import ButtonComponent from '../../atom/ButtonComponent/ButtonComponent';
import EmailIcon from '@mui/icons-material/Email';
import TwitterIcon from '@mui/icons-material/Twitter';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

function FooterComponent() {
  const navigate = useNavigate();

  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className='footer-content-header'>
          <div className="footer-logo">
            <img src={CommunityPortalImage} alt="Community Portal" />
            <span>SportsTalk</span>
          </div>
          <div className="footer-subscribe">
            <EmailIcon className="mail-icon" />
            <span>Get the latest from EdgeJumps</span>
            <ButtonComponent onClick={() => {navigate('/subscribe');}}>Sign Up Here</ButtonComponent>
          </div>
        </div>
        <div className="footer-content-body">
          <div className="footer-content-company-description">
          EdgeJumps revolutionalizes the sports community with our mobile app, a dynamic platform connecting enthusiasts worldwide. Our app facilitates finding sports communities and offers interactive experiences like video sharing and live streaming.
          </div>
          <div className="footer-content-company-links">
            <div className="footer-content-company-links-column">
              <Link to="/" className="footer-content-company-links-header">Home</Link>
            </div>
            <div className="footer-content-company-links-column">
              <div className="footer-content-company-links-header">Support</div>
              <Link to="/get-started" className="footer-content-company-links-item">Getting started</Link>
              <Link to="/releases" className="footer-content-company-links-item">What's new</Link>
              <a href="https://community.sportstalk.mobi" className="footer-content-company-links-item" target="_blank" rel="noopener noreferrer">Community Portal</a>
            </div>
            <div className="footer-content-company-links-column">
              <div className="footer-content-company-links-header" >Company</div>
              <Link to="/about-edgejumps" className="footer-content-company-links-item">About Us</Link>
              <Link to="/edgejumps-news" className="footer-content-company-links-item">News</Link>
              <Link to="/contact" className="footer-content-company-links-item">Contact Us</Link>
            </div>
          </div>
        </div>
        <div className="footer-content-connect">
          <p className="footer-paragraph">Connect with us</p>
          <div className="footer-socials">
            <a href="https://www.twitter.com/sportstalk2024" target="_blank" rel="noopener noreferrer">
              <TwitterIcon />
            </a>
            {/* <a href="https://linkedin.com/YourPage" target="_blank" rel="noopener noreferrer">
              <LinkedInIcon />
            </a> */}
            <a href="https://facebook.com/edgejumps" target="_blank" rel="noopener noreferrer">
              <FacebookIcon />
            </a>
            <a href="https://www.instagram.com/edgejumps" target="_blank" rel="noopener noreferrer">
              <InstagramIcon />
            </a>
            <a href="https://www.youtube.com/@edgejumps" target="_blank" rel="noopener noreferrer">
              <YouTubeIcon />
            </a>
          </div>
        </div>
      </div>
      <BottomBarComponent />
    </footer>
  );
}

export default FooterComponent;

