import React from 'react';
import './SportsOrg.css';
import CardComponent from '../../../components/molecule/CardComponent/CardComponent';
import AccordionComponent from '../../../components/molecule/AccordionComponent/AccordionComponent';
import ProductLayout from '../../../layouts/ProductLayout/ProductLayout';
import StepperComponent from '../../../components/molecule/StepperComponent/StepperComponent';
import { cardItems, accordionItems, steps } from './constants';

function SportsOrg() {
  
  return (
    <ProductLayout
      subheader="Organizational Dashboard"
      header="Empowering Sports at a Higher Level with SportsTalk"
      description="As a Sports Organization Admin on SportsTalk, you are at the forefront of managing and orchestrating sports excellence. Discover the robust features tailored to elevate your organization's operations!"
      footerHeader={<h1>What's Next for Your Sports Organization <span className="footer-text-white">- Elevate, Centralize, and Thrive</span></h1>}
      footerDescription={[
        <p>SportsTalk is committed to empowering sports organizations with the tools they need to succeed. As an organization admin, you play a crucial role in centralizing operations and ensuring a seamless experience for all affiliated clubs.</p>,
        <p>Join us in revolutionizing the way sports organizations operate. Whether you're managing ratings, overseeing multiple clubs, or ensuring data accuracy through API integration, SportsTalk is your partner in achieving sports excellence.</p>
      ]}
      footerSubheader={<h2>Choose innovation, choose efficiency, choose SportsTalk!</h2>}
    >
      <div className="sports-org">
        <div className="sports-org-key-features">
          <h1>Revolutionizing Sports Management with Cutting-Edge Features</h1>
          <div className="sports-org-key-features-cards-container" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {cardItems.map((item, index) => (
              <CardComponent
                key={index}
                img={item.img}
                heading={item.heading}
                subheading={item.subheading}
                text={item.text}
                cardsPerRow={3}
                canLearnMore={false}
                canShare={false}
              />
            ))}
          </div>
        </div>
        <div className="sports-org-api-steps">
          <h1>Streamlining Operations with Advanced API Solutions</h1>
          <div className="sports-org-api-steps-stepper-container">
            <div className="sports-org-api-steps-stepper">
              <StepperComponent steps={steps} />
            </div>
          </div>
        </div>
        <div className="sports-org-organization-features">
          <h1>Maximize Your Administrative Capabilities With...</h1>
          <div className="sports-org-organization-features-accordion-container">
            {accordionItems.map((item, index) => (
              <AccordionComponent
                key={index}
                title={item.title}
                details={item.details}
              />
            ))}
          </div>
        </div>
      </div>
    </ProductLayout>
  );
}

export default SportsOrg;
