import Integration from '../../../assets/icons/integration.png';
import Features from '../../../assets/icons/edition.png'
import Accuracy from '../../../assets/icons/accuracy.png';
import LinkIcon from '@mui/icons-material/Link';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import TuneIcon from '@mui/icons-material/Tune';

export const cardItems = [
    {
        img: Integration, 
        heading: 'API Integration',
        subheading: 'Effortless Integration, Seamless Synchronization',
        text: 'Synchronize rating scores effortlessly with our advanced API integration.'
    },
    {
        img: Features, 
        heading: 'Comprehensive Features for Sports Clubs',
        subheading: 'Empower Your Clubs',
        text: 'Extend the power of SportsTalk to each affiliated club with all-inclusive features.'
    },
    {
        img: Accuracy,
        heading: 'Enhancing Data Accuracy',
        subheading: 'Real-Time Synchronization',
        text: 'Ensure data accuracy with real-time synchronization of player skills and achievements.'
    }
];

export const accordionItems = [
    {
        title: 'Unified Management',
        details: 'Access a centralized dashboard to oversee and manage all affiliated clubs within your organization.'
    },
    {
        title: 'Consistent Experience',
        details: 'Ensure that every club under your organization benefits from the same suite of powerful features available to individual clubs.'
    },
    {
        title: 'Efficient Communication',
        details: 'Streamline communication and collaboration across all levels of your sports organization.'
    }
];

export const steps = [
    {
        label: 'Effortless Connection',
        description: 'Utilize our API to seamlessly connect your organization\'s database with SportsTalk.',
        icon: LinkIcon
    },
    {
        label: 'Real-Time Synchronization',
        description: 'Enable real-time synchronization of rating scores, ensuring the data is always accurate.',
        icon: AutorenewIcon
    },
    {
        label: 'Customization Options',
        description: 'Tailor the integration to fit the specific needs and structure of your organization.',
        icon: TuneIcon
    }
];
