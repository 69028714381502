import React from 'react';
import { Link } from 'react-router-dom';
import './BottomBarComponent.css';

const BottomBarComponent = () => {
    return (
        <div className="bottom-bar-component">
            <div className="left">
                &copy; 2023 Edgejumps Inc. &reg; All rights reserved.
            </div>
            <div className="right">
                <Link to="/privacy-policy" className="link">Privacy Policy</Link> | 
                <Link to="/terms-and-conditions" className="link">Terms of Use</Link>
            </div>
        </div>
    );
}

export default BottomBarComponent;
