import TableTennis from '../../../assets/icons/tabletennis.png';

export const eventItems = [
    {
        img: TableTennis,
        heading: "Table Tennis Showdown",
        subheading: "March 15, 2024 - SportsTalk Arena",
        text: "Witness a clash of titans as top players compete for glory in an adrenaline-pumping Table Tennis showdown. Experience intense rallies and strategic plays in a showcase of skill and sportsmanship.",
    },
    {
        img: TableTennis,
        heading: "Community Play Day",
        subheading: "April 5, 2024 - Various SportsTalk Clubs",
        text: "Join us for a day of casual play and community bonding. All skill levels are welcome in this perfect opportunity to connect with fellow Table Tennis enthusiasts.",
    },
    {
        img: TableTennis,
        heading: "Rising Stars Tournament",
        subheading: "May 20, 2024 - SportsTalk Arena",
        text: "Cheer for young talents in the Rising Stars Tournament as they showcase their skills and compete for recognition in the world of Table Tennis.",
    },
];