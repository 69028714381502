import React from 'react';
import { useNavigate } from 'react-router-dom';
import CardComponent from '../../components/molecule/CardComponent/CardComponent';
import PostingLayout from '../../layouts/PostingLayout/PostingLayout';
import { ReleaseItems } from './constants';

function Releases() {

  const navigate = useNavigate();

  const handleLearnMore = (path) => {
    navigate(path);
  };

  return (
    <PostingLayout
      hasBackButton={false}
      subheader="What's New"
      header="The Latest and Greatest from SportsTalk"
      description="Explore Sportstalk's latest game-changing updates, features, and enhancements. Join the conversation and experience sports like never before with our newest releases."
    >
      {ReleaseItems.map((item, index) => (
        <CardComponent
          key={index} 
          img={item.img}
          heading={item.heading}
          subheading={item.subheading}
          text={item.text}
          canLearnMore={item.canLearnMore}
          cardsPerRow={2}
          onLearnMore={() => handleLearnMore(item.learnMorePath)}
        />
      ))}
    </PostingLayout>
  );
}

export default Releases;
