import React from 'react';
import './WhyChooseUs.css';
import WhySportsTalkLayout from '../../../layouts/WhySportsTalkLayout/WhySportsTalkLayout';
import CardComponent from '../../../components/molecule/CardComponent/CardComponent';
import { cardData } from './constants';

function WhyChooseUs() {
  const header = "Why Choose SportsTalk?";
  const subheader = "At Sportstalk, We Connect Sports Enthusiasts";
  const description = "At Sportstalk, we've created more than just a platform. We've crafted a vibrant community that connects sports enthusiasts from around the world. Whether you're a seasoned athlete or just starting your sports journey, Sportstalk is your ultimate destination to unleash your passion.";
  const footerHeader = "Join Our Community";
  const footerDescription = "Choose Sportstalk, where every play, post, and connection counts. Join us in celebrating the spirit of sportsmanship and camaraderie!";
  const footerSubheader = "Experience the Difference";

  const cardComponents = cardData.map((data, index) => (
    <CardComponent
      key={index}
      img={data.imageUrl}
      heading={data.heading}
      subheading={""} 
      text={data.paragraph}
      cardsPerRow={3} 
      canShare={false} 
      canLearnMore={false} 
    />
  ));

  return (
    <WhySportsTalkLayout
      subheader={subheader}
      header={header}
      description={description}
      footerHeader={footerHeader}
      footerDescription={footerDescription}
      footerSubheader={footerSubheader}
    >
      {cardComponents}
    </WhySportsTalkLayout>
  );
}

export default WhyChooseUs;
