import React from 'react';
import './News.css';

function News() {
  return (
    <div>
      News
    </div>
  );
}

export default News;
