import React from 'react';
import './TopBarLinkComponent.css';
import { Link } from 'react-router-dom';

const TopBarLinkComponent = ({ label, url }) => {
  return (
    <Link to={url} className={`top-bar-link-component`}>
      {label}
    </Link>
  );
};

export default TopBarLinkComponent;
