import React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import './TimelineComponent.css'

function TimelineComponent({ steps }) {
  return (
    <Timeline className="timeline-component" position="alternate">
      {steps.map((step, index) => (
        <TimelineItem key={index}>
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector sx={{ bgcolor: 'var(--navy)' }} />
              <TimelineDot sx={{ bgcolor: 'var(--navy)', color: '#fff', width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {step.icon}
              </TimelineDot>
            <TimelineConnector sx={{ bgcolor: 'var(--navy)' }} />
          </TimelineSeparator>
          <TimelineContent sx={{ 
            py: '12px', 
            px: 2,
          }}>
            <Typography
              variant="subtitle1"
              sx={{ 
                fontWeight: '600',
                padding: '0.5rem 3rem',
                fontFamily: 'Poppins, sans-serif'
              }}
            >
              {step.label}
            </Typography>
            <Typography 
              variant="h5" 
              component="span" 
              className="title-style" // Apply the CSS class
              sx={{ 
                fontWeight: '700',
                fontFamily: 'Poppins, sans-serif',
                padding: '0 3rem',
              }}
            >
              {step.title}
            </Typography>
            <Typography
              variant="body1"
              sx={{ 
                fontWeight: '500',
                padding: '0.5rem 3rem',
                fontFamily: 'Poppins, sans-serif'
              }}
            >
              {step.description}
            </Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}

export default TimelineComponent;
