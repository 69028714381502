import React from 'react';
import './Home.css'; 
import BillboardLayout from '../../layouts/BillboardLayout/BillboardLayout';
import BillBoardImage from '../../assets/icons/billboard-ai.png'

const Home = () => {

  const handleButtonClick = () => {
    console.log('Button clicked');
  };

  const heading = (
    <>
      <span className={"home-title-white"}>Play Hard.</span><br />
      <span className={"home-title-orange"}>Connect Easy.</span>
    </>
  );

  const paragraph = (
    <>
      <p className="home-paragraph">Step into the game with our app, where sports enthusiasts unite and passions ignite. Connect, share, and engage with a global community of fans with SportsTalk.</p>
    </>
  );
  
  return (
    <div className="home">
      <BillboardLayout
        heading={heading}
        paragraph={paragraph}
        imageUrl={BillBoardImage}
        buttonText="Join the Community"
        onButtonClick={handleButtonClick}
      />
    </div>
  );
}

export default Home;
