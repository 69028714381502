import React from 'react';
import './Blogs.css';
// import CardComponent from '../../../components/molecule/CardComponent/CardComponent';
import PostingLayout from '../../../layouts/PostingLayout/PostingLayout';

function Blogs() {

  return (
    <PostingLayout
      hasBackButton={false}
      subheader="SportsTalk Blogs"
      header="Your Platform to Share, Discuss, and Connect"
      description="Welcome to the SportsTalk Blogs, where the sports community comes together to share insights, experiences, and passions. This is your space to post your favorite topics related to SportsTalk, engage with other users, and connect with our dedicated moderation team. Let the conversations begin!"
    >
      <div className="blogs">
      </div>
      
    </PostingLayout>
  );
}

export default Blogs;
