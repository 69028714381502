import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import CarouselItemComponent from '../../atom/CarouselItemComponent/CarouselItemComponent';
import './CarouselComponent.css';

const CarouselComponent = ({ items }) => {

  return (
    <div className="carousel-component">
      <Swiper
        className="carousel-component-slider"
        modules={[Navigation, Pagination, A11y]}
        spaceBetween={50}
        navigation
        pagination={{ clickable: true }}
        loop={true}
      >
        {items.map((item, index) => (
          <SwiperSlide key={index}>
            <CarouselItemComponent
              image={item.image}
              heading={item.heading}
              subheading={item.subheading}
              paragraph={item.paragraph}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CarouselComponent;
