import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Releases from '../pages/Releases/Releases';
import ScoreboardIntegrationRelease from '../pages/Releases/ScoreboardIntegrationRelease/ScoreboardIntegrationRelease';

const ReleasesRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Releases />} />
      <Route path="/ai-scoreboard-integration" element={<ScoreboardIntegrationRelease />} />
    </Routes>
  );
};

export default ReleasesRoutes;
