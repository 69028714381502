import ConnectLogo from '../../../assets/icons/why-section1.png';

export const cardData = [
  {
    img: ConnectLogo,
    heading: "Live Streaming Excellence",
    subheading: "Bringing Games to Life",
    text: "Go beyond traditional sharing—bring your games and practices to life with Sportstalk's live streaming feature. Engage with your audience, receive real-time feedback, and build a dedicated fanbase as you showcase your skills.",
    canShare: false,
    canLearnMore: false,
  },
  {
    img: ConnectLogo,
    heading: "Personalized Player Ratings",
    subheading: "Showcase Your Skills",
    text: "Your skills deserve recognition. Sportstalk's integrated rating system allows you to build a comprehensive profile that reflects your achievements and abilities. Challenge yourself, compete with others, and let your rating speak volumes about your sportsmanship.",
    canShare: false,
    canLearnMore: false,
  },
  {
    img: ConnectLogo,
    heading: "Community-driven Content",
    subheading: "Join the Conversation",
    text: "Immerse yourself in a community that lives and breathes sports. Share your sports posts, join conversations, and stay updated on the latest trends and news. Sportstalk is more than a platform; it's a dynamic community that celebrates the spirit of sportsmanship.",
    canShare: false,
    canLearnMore: false,
  },
  {
    img: ConnectLogo,
    heading: "Stay Informed and Inspired",
    subheading: "Your Daily Dose of Sports",
    text: "Sportstalk keeps you in the know with real-time sports updates, news, and insights. Be inspired by the achievements of others, stay informed about upcoming events, and be part of a community that fuels your passion for sports.",
    canShare: false,
    canLearnMore: false,
  }
];