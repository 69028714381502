import React from 'react';
import { Button } from '@mui/material';
import './ProductLayout.css';
import { useNavigate } from 'react-router-dom';

function ProductLayout({ subheader, header, description, children, footerHeader, footerDescription, footerSubheader }) {

  const navigate = useNavigate();

  const handleCTAClick = () => {
    navigate('/get-started');
  }

  return (
    <div className="product-template">
      <div className="product-header">
        <div>
          <h2>{subheader}</h2>
          <h1>{header}</h1>
          <p>{description}</p>
        </div>
      </div>
      <div className="product-body">
        {children}
      </div>
      <div className="product-footer-dark">
        <div className="product-footer">
          {footerHeader}
          {footerDescription}
          {footerSubheader}
          <div className="cta-button-container">
            <h1>Try SportsTalk For Free Today!</h1>
            <Button
              onClick={handleCTAClick}
              className="cta-button"
              variant="contained"
            >
              Get Started
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductLayout;
