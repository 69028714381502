import React from 'react';
import './MeetSportsTalk.css';
import PostingLayout from '../../../layouts/PostingLayout/PostingLayout';
import CardComponent from '../../../components/molecule/CardComponent/CardComponent';
import { cardData } from './constants';

function MeetSportsTalk() {
  return (
    <PostingLayout
      hasBackButton={false}
      subheader="SportsTalk"
      header="Redefining Your Sports Experience"
      description="Welcome to Sportstalk, the ultimate platform designed to elevate your sports experience. Dive into a world where passion meets innovation, and where every player has a voice. Here's an in-depth look at what makes Sportstalk the go-to platform for sports enthusiasts."
    >
      <div className="card-container"> 
        {cardData.map((data, index) => (
          <CardComponent key={index} cardsPerRow={3} {...data} />
        ))}
      </div>
    </PostingLayout>
    
  );
}

export default MeetSportsTalk;
