import React from 'react';
import { useNavigate } from 'react-router-dom';
import './TermsAndConditions.css'

const TermsAndConditions = ({ isLockdown = false }) => {
  const navigate = useNavigate();
  const goBack = () => {
      navigate(-1); 
  };
  return (
    <div className="terms-and-conditions">
      <div className={`terms-and-conditions-container ${isLockdown ? '--is-lockdown' : ''}`}>
        <button onClick={goBack} className="back-button">{"< Back"}</button>
        <h1>Terms and Conditions</h1>
        <p>These terms and conditions govern the use of sportstalk.mobi (the "Site"). This Site is owned and operated by Edgejumps Inc. This Site is an ecommerce website.</p>
        
        <section>
            <h2>Intellectual Property</h2>
            <p>All content published and made available on our Site is the property of Edgejumps Inc. and the Site's creators. This includes, but is not limited to images, text, logos, documents, downloadable files and anything that contributes to the composition of our Site.</p>
        </section>

        <section>
            <h2>Age Restrictions</h2>
            <p>The minimum age to use our Site is 13 years old. By using this Site, users agree that they are over 13 years old. We do not assume any legal responsibility for false statements about age.</p>
        </section>

        <section>
            <h2>User Contributions</h2>
            <p>Users may post the following information on our Site:</p>
            <ol>
                <li>Photos;</li>
                <li>Videos; and</li>
                <li>Public comments.</li>
            </ol>
            <p>By posting publicly on our Site, you agree not to act illegally or violate these Terms and Conditions.</p>
        </section>

        <section>
            <h2>Accounts</h2>
            <p>When you create an account on our Site, you agree to the following:</p>
            <ol>
                <li>You are solely responsible for your account and the security and privacy of your account, including passwords or sensitive information attached to that account; and</li>
                <li>All personal information you provide to us through your account is up to date, accurate, and truthful and that you will update your personal information if it changes.</li>
            </ol>
            <p>We reserve the right to suspend or terminate your account if you are using our Site illegally or if you violate these Terms and Conditions.</p>
        </section>

        <section>
            <h2>Sale of Goods And Services</h2>
            <p>These Terms and Conditions govern the sale of goods and services available on our Site.</p>
            <p>The following goods are available on our Site:</p>
            <ol>
                <li>Membership.</li>
            </ol>
            <p>The following services are available on our Site:</p>
            <ol>
                <li>Premium services.</li>
            </ol>
            <p>The services will be paid for in full when the services are ordered.</p>
            <p>These Terms and Conditions apply to all the goods and services that are displayed on our Site at the time you access it. This includes all products listed as being out of stock. All information, descriptions, or images that we provide about our goods and services are as accurate as possible. However, we are not legally bound by such information, descriptions, or images as we cannot guarantee the accuracy of all goods and services we provide. You agree to purchase goods and services from our Site at your own risk.</p>
            <p>We reserve the right to modify, reject or cancel your order whenever it becomes necessary. If we cancel your order and have already processed your payment, we will give you a refund equal to the amount you paid. You agree that it is your responsibility to monitor your payment instrument to verify receipt of any refund.</p>
        </section>

        <section>
            <h2>User Goods and Services</h2>
            <p>Our Site allows users to sell goods and services. We do not assume any responsibility for the goods and services users sell on our Site. We cannot guarantee the quality or accuracy of any goods and services sold by users on our Site. However, if we are made aware that a user is violating these Terms and Conditions, we reserve the right to suspend or prohibit the user from selling goods and services on our Site.</p>
        </section>

        <section>
            <h2>Subscriptions</h2>
            <p>Your subscription automatically renews and you will be automatically billed until we receive notification that you want to cancel the subscription.</p>
            <p>To cancel your subscription, please follow these steps: The subscription can be cancelled any time. However, the paid membership will not be refunded. The membership for the current month will remain active until the next cycle of subscription.</p>
        </section>

        <section>
            <h2>Free Trial</h2>
            <p>We offer the following free trial of our goods and services: 7 day free trial that begins when users register for a new account. The free trial includes unlimited access to all documents available on our site.</p>
            <p>At the end of your free trial, the following will occur: You will automatically be billed our monthly subscription rate. If you do not want to be billed, you will need to cancel your subscription before your free trial ends.</p>
            <p>To cancel your free trial, please follow these steps: Log in to your account and select "Cancel Free Trial" under the "Account Management" tab.</p>
        </section>

        <section>
            <h2>Payments</h2>
            <p>We accept the following payment methods on our Site:</p>
            <ol>
                <li>Credit Card;</li>
                <li>PayPal; and</li>
                <li>Debit.</li>
            </ol>
            <p>When you provide us with your payment information, you authorize our use of and access to the payment instrument you have chosen to use. By providing us with your payment information, you authorize us to charge the amount due to this payment instrument.</p>
            <p>If we believe your payment has violated any law or these Terms and Conditions, we reserve the right to cancel or reverse your transaction.</p>
        </section>

        <section>
            <h2>Consumer Protection Law</h2>
            <p>Where the Consumer Protection Act, or any other consumer protection legislation in your jurisdiction applies and cannot be excluded, these Terms and Conditions will not limit your legal rights and remedies under that legislation. These Terms and Conditions will be read subject to the mandatory provisions of that legislation. If there is a conflict between these Terms and Conditions and that legislation, the mandatory provisions of the legislation will apply.</p>
        </section>

        <section>
            <h2>Links to Other Websites</h2>
            <p>Our Site contains links to third party websites or services that we do not own or control. We are not responsible for the content, policies, or practices of any third party website or service linked to on our Site. It is your responsibility to read the terms and conditions and privacy policies of these third party websites before using these sites.</p>
        </section>

        <section>
            <h2>Limitation of Liability</h2>
            <p>Edgejumps Inc. and our directors, officers, agents, employees, subsidiaries, and affiliates will not be liable for any actions, claims, losses, damages, liabilities and expenses including legal fees from your use of the Site.</p>
        </section>

        <section>
            <h2>Indemnity</h2>
            <p>Except where prohibited by law, by using this Site you indemnify and hold harmless Edgejumps Inc. and our directors, officers, agents, employees, subsidiaries, and affiliates from any actions, claims, losses, damages, liabilities and expenses including legal fees arising out of your use of our Site or your violation of these Terms and Conditions.</p>
        </section>

        <section>
            <h2>Applicable Law</h2>
            <p>These Terms and Conditions are governed by the laws of the Province of Ontario.</p>
        </section>

        <section>
            <h2>Severability</h2>
            <p>If at any time any of the provisions set forth in these Terms and Conditions are found to be inconsistent or invalid under applicable laws, those provisions will be deemed void and will be removed from these Terms and Conditions. All other provisions will not be affected by the removal and the rest of these Terms and Conditions will still be considered valid.</p>
        </section>

        <section>
            <h2>Changes</h2>
            <p>These Terms and Conditions may be amended from time to time in order to maintain compliance with the law and to reflect any changes to the way we operate our Site and the way we expect users to behave on our Site. We will notify users by email of changes to these Terms and Conditions or post a notice on our Site.</p>
        </section>

        <section>
            <h2>Contact Details</h2>
            <p>Please contact us if you have any questions or concerns. Our contact details are as follows:</p>
            <p><a href="mailto:support@edgejumps.com">support@edgejumps.com</a></p>
            <p>You can also contact us through the feedback form available on our Site.</p>
        </section>

        <section>
            <h2>Effective Date</h2>
            <p>Effective Date: 21st day of November, 2023</p>
        </section>

      </div>
    </div>
  );
}

export default TermsAndConditions;
