import React from 'react';
import { useSearchBarContext } from '../../context/SearchContext';
import { useNotificationContext } from '../../context/NotificationContext';
import TopNavBarComponent from '../../components/organism/TopNavBarComponent/TopNavBarComponent';
import './MainLayout.css';
import NotificationBarComponent from '../../components/molecule/NotificationBarComponent/NotificationBarComponent';
import FooterComponent from '../../components/organism/FooterComponent/FooterComponent';
import CookieConsentBar from '../../components/organism/CookieConsentBar/CookieConsentBar';

import notificationMessages from '../../constants/notificationMessages.json';

const MainLayout = ({ children }) => {
  const { isSearchBarOpen } = useSearchBarContext();
  const { isNotificationVisible, hideNotification } = useNotificationContext();

  return (
    <div className="main-layout">
      {isNotificationVisible && 
        <NotificationBarComponent message={notificationMessages.notificationMessage} onClick={hideNotification} />
      }
      <TopNavBarComponent className={isNotificationVisible ? 'top-nav-bar-with-notification' : ''} />
      <div className={`main-layout-scrollable-content ${isSearchBarOpen ? 'search-bar-open' : ''} ${isNotificationVisible ? 'main-content-with-notification' : ''}`}>
        <div className="main-layout-content">
          {children}
        </div>
        <CookieConsentBar />
        <FooterComponent />
      </div>
    </div>
  );
};

export default MainLayout;
