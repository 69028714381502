import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { SearchBarProvider } from '../context/SearchContext';
import { NotificationProvider } from '../context/NotificationContext';
import MainLayout from '../layouts/MainLayout/MainLayout';
import Home from '../pages/Home/Home';
import ComingSoonLockdown from '../pages/ComingSoonLockdown/ComingSoonLockdown';
import GetStarted from '../pages/GetStarted/GetStarted';
import Playground from '../pages/Playground/Playground';
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from '../pages/TermsAndConditions/TermsAndConditions';
import Comparisons from '../pages/WhySportsTalk/Comparisons/Comparisons';
import Customers from '../pages/WhySportsTalk/Customers/Customers';
import WhyChooseUs from '../pages/WhySportsTalk/WhyChooseUs/WhyChooseUs';
import ReleasesRoutes from './ReleasesRoutes';
import News from '../pages/News/News';
import Club from '../pages/Product/Club/Club';
import Individual from '../pages/Product/Individual/Individual';
import MeetSportsTalk from '../pages/Product/MeetSportsTalk/MeetSportsTalk';
import SportsOrg from '../pages/Product/SportsOrg/SportsOrg';
import Blogs from '../pages/Resources/Blogs/Blogs';
import Documentation from '../pages/Resources/Documentation/Documentation';
import Events from '../pages/Resources/Events/Events';
import SportsTalkTV from '../pages/Resources/SportsTalkTV/SportsTalkTV';
import SignUpUpdates from '../pages/SignUpUpdates/SignUpUpdates';
import config from '../constants/config.json';
import Contact from '../pages/Contact/Contact';
import CompanyAboutUs from '../pages/CompanyAboutUs/CompanyAboutUs';
import CompanyNews from '../pages/CompanyNews/CompanyNews';

const RoutesComponent = () => {

  const [isLockdown, setIsLockdown] = useState(false);

  useEffect(() => {
    setIsLockdown(config.lockdown); 
  }, []);

  if (isLockdown) {
    return (
      <Routes>
        <Route path="/" element={<ComingSoonLockdown />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions isLockdown />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy isLockdown />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  }
  

  return (
    <SearchBarProvider> 
      <NotificationProvider>
        <MainLayout>
          <Routes>
            {/* playground */}
            <Route path="/playground" element={<Playground />} />
            {/* terms and policy */}
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            {/* home */}
            <Route path="/" element={<Home />} />
            {/* get started */}
            <Route path="/get-started" element={<GetStarted />} />
            {/* why SportsTalk */}
            <Route path="/comparisons" element={<Comparisons />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/why-choose-us" element={<WhyChooseUs />} />
            {/* what's new */}
            <Route path="/releases/*" element={<ReleasesRoutes />} />
            {/* product */}
            <Route path="/club" element={<Club />} />
            <Route path="/individual" element={<Individual />} />
            <Route path="/meet-sportstalk" element={<MeetSportsTalk />} />
            <Route path='/sports-organization' element={<SportsOrg />} />
            {/* resources */}
            <Route path='/blogs' element={<Blogs />} />
            <Route path='/documentation' element={<Documentation />} />
            <Route path='/events' element={<Events />} />
            <Route path='/sportstalk-tv' element={<SportsTalkTV />} />
            {/* news */}
            <Route path="/news" element={<News />} />
            {/* signup for updates */}
            <Route path="/subscribe" element={<SignUpUpdates />} />
            {/* company */}
            <Route path="/about-edgejumps" element={<CompanyAboutUs />} />
            <Route path="/edgejumps-news" element={<CompanyNews />} />
            {/* contact page */}
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </MainLayout>
      </NotificationProvider>
    </SearchBarProvider>
  );
};

export default RoutesComponent;
