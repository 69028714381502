import React from 'react';
import './NotificationBarComponent.css';

const NotificationBarComponent = ({ message, onClick }) => {
    return (
      <div className="notification-bar">
        <span className="notification-message">{message}</span>
        <button className="dismiss-button" onClick={onClick}>X</button>
      </div>
    );
  };

export default NotificationBarComponent;
