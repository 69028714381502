import React from 'react';
import PostingLayout from '../../../layouts/PostingLayout/PostingLayout';
import CardComponent from '../../../components/molecule/CardComponent/CardComponent';
import "./ScoreboardIntegrationRelease.css";
import { scoreboardFeatureCards } from '../constants';

function ScoreboardIntegrationRelease() {
  
  return (
    <PostingLayout
      subheader="Transforming Live Streams, Elevating Viewer Experience"
      header="AI-Based Scoreboard Integration"
      description="We're excited to announce the integration of an AI-based scoreboard into our live streaming feature..."
    >
      <div className="releases-scoreboard-integration">
        {scoreboardFeatureCards.map((card, index) => (
          <CardComponent key={index} {...card} />
        ))}
      </div>
    </PostingLayout>
  );
}

export default ScoreboardIntegrationRelease;
