import React from 'react';
import ButtonComponent from '../../components/atom/ButtonComponent/ButtonComponent';

const Playground = () => {
  return (
    <div>
      <h1>Component Playground</h1>
      <div className="playground-button-test">
        <ButtonComponent onClick={() => console.log('Clicked!')}>
            Click Me
        </ButtonComponent>
      </div>
    </div>
  );
}

export default Playground;
