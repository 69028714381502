import ConnectLogo from '../../../assets/icons/network.png';
import TeamLogo from '../../../assets/icons/teams.png';
import StreamingLogo from '../../../assets/icons/streaming.png';
import CompetitionLogo from '../../../assets/icons/competition.png';
import UpdatesLogo from '../../../assets/icons/updates.png';

export const cardData = [
  {
    heading: "Connect with Like-minded Players",
    paragraph: "Sportstalk brings together a diverse community of sports players, creating a space where you can connect with like-minded individuals who share your passion for the game. Find your tribe, join clubs, and build lasting friendships with players who understand your love for sports.",
    imageUrl: ConnectLogo,
    headingColor: 'white',
    textColor: 'rgba(255, 255, 255, 0.5)',
  },
  {
    heading: "Discover Clubs and Teams",
    paragraph: "Looking for the perfect team or club to join? SportsTalk simplifies the process by providing a platform where you can easily discover and connect with clubs that match your playing style and preferences. Elevate your game by being part of a community that supports and challenges you to reach new heights.",
    imageUrl: TeamLogo,
    headingColor: 'white',
    textColor: 'rgba(255, 255, 255, 0.5)',
  },
  {
    heading: "Showcase Your Skills with Live Streaming",
    paragraph: "Bring your game to life with SportsTalk's live streaming feature. Showcase your skills, share thrilling moments, and engage with your followers in real-time. Whether you're making a game-winning shot or perfecting your technique, SportsTalk lets you share your sports journey with the world.",
    imageUrl: StreamingLogo,
    headingColor: 'white',
    textColor: 'rgba(255, 255, 255, 0.5)',
  },
  {
    heading: "Unleash Your Competitive Spirit",
    paragraph: "Our integrated rating system allows you to showcase your skills and achievements, creating a dynamic profile that reflects your true sporting prowess. Compete with others, climb the ranks, and let your rating speak volumes about your dedication and talent.",
    imageUrl: CompetitionLogo,
    headingColor: 'white',
    textColor: 'rgba(255, 255, 255, 0.5)',
  },
  {
    heading: "Stay Informed with Sports Updates",
    paragraph: "SportsTalk keeps you in the loop with the latest sports news, updates, and trends. Stay informed about upcoming events, competitions, and breakthroughs in the sports world. Fuel your passion by being part of a community that lives and breathes sports.",
    imageUrl: UpdatesLogo,
    headingColor: 'white',
    textColor: 'rgba(255, 255, 255, 0.5)',
  },
];
