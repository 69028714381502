import React, { useState } from 'react';
import './TopNavBarComponent.css';
import { useNavigate } from 'react-router-dom';
import { useSearchBarContext } from '../../../context/SearchContext';
import CommunityPortalImage from '../../../assets/icons/CommunityPortal.png';
import NavigationComponent from '../../molecule/NavigationComponent/NavigationComponent';
import ButtonComponent from '../../atom/ButtonComponent/ButtonComponent';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

const TopNavBarComponent = ({ className }) => {
  const { setIsSearchBarOpen } = useSearchBarContext();
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const handleSearchToggle = () => {
    setIsSearchBarOpen(prevState => !prevState);
    setSearchOpen(!searchOpen);
  };

  const handleClearSearch = () => {
    setSearchText('');
  };

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <div className={`top-nav-bar-component ${className}`}>
      <div className="top-nav-bar-logo" onClick={handleLogoClick}>
        <img src={CommunityPortalImage} alt="Community Portal" />
        <span>EdgeJumps</span>
      </div>
      <NavigationComponent />
      <div 
        className={`search-icon-container ${isHovered || searchOpen ? '--open' : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleSearchToggle}
      >
        <SearchIcon className="dropdown-search-icon" style={{ fontSize: '20px' }} />
      </div>
      {searchOpen && (
        <div className="dropdown-full-width-search-bar">
          <TextField
            fullWidth
            variant="outlined"
            placeholder="What are you looking for?"
            value={searchText}
            onChange={handleSearchTextChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <ClearIcon onClick={handleClearSearch} />
                </InputAdornment>
              ),
            }}
          />
        </div>
      )}
      <div className="top-nav-bar-buttons">
        <ButtonComponent variant="outlined">Community Portal</ButtonComponent>
        <ButtonComponent onClick={() => {navigate('/get-started');}}>Get Started</ButtonComponent>
      </div>
    </div>
  );
};

export default TopNavBarComponent;
