import React, { useState } from 'react';
import Modal from 'react-modal';
import Divider from '@mui/material/Divider';
import './PrivacyModalComponent.css';
import ButtonComponent from '../../atom/ButtonComponent/ButtonComponent';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Switch,
} from '@mui/material';

const PrivacyModalComponent = ({ isOpen, onRequestClose, onConfirm, onReject }) => {
    
    const [cookiePreferences, setCookiePreferences] = useState({
        strictlyNecessary: false,
        performanceFunctionality: false,
        analyticsCustomization: false,
        targeting: false,
    });

    const handleTogglePreference = (preference) => {
        setCookiePreferences((prevPreferences) => ({
            ...prevPreferences,
            [preference]: !prevPreferences[preference],
        }));
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Privacy Preference Center"
        >
            <div className="privacy-modal-wrapper">
                <div className="privacy-modal-header">
                    <h2>Privacy Preference Center</h2>
                    <button className="privacy-modal-close-button" onClick={onRequestClose}>
                        X
                    </button>
                </div>
                <Divider />
                <div className="privacy-modal-body">
                    <p>
                        Cookies are small text files that websites place on a user's device,
                        such as their computer or smartphone, to perform various functions.
                        They serve as tools for session management, personalization, analytics,
                        and targeted advertising. In the context of a "Privacy Preference Center,"
                        cookies are essential for enabling users to customize their consent
                        preferences related to data tracking and storage. This center empowers
                        users to make informed decisions about how their data is collected and
                        used, providing transparency and control over their online privacy. By
                        adjusting their cookie preferences, users can shape their online experience
                        and privacy in alignment with their preferences and values.
                    </p>
                    <h3>Manage consent preferences</h3>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Strictly Necessary Website Cookies</TableCell>
                                    <TableCell>
                                        <Switch
                                            checked={cookiePreferences.strictlyNecessary}
                                            onChange={() => handleTogglePreference('strictlyNecessary')}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Performance and Functionality Cookies</TableCell>
                                    <TableCell>
                                        <Switch
                                            checked={cookiePreferences.performanceFunctionality}
                                            onChange={() => handleTogglePreference('performanceFunctionality')}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Analytics and Customization Cookies</TableCell>
                                    <TableCell>
                                        <Switch
                                            checked={cookiePreferences.analyticsCustomization}
                                            onChange={() => handleTogglePreference('analyticsCustomization')}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Targeting Cookies</TableCell>
                                    <TableCell>
                                        <Switch
                                            checked={cookiePreferences.targeting}
                                            onChange={() => handleTogglePreference('targeting')}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <Divider />
                <div className="privacy-modal-footer">
                    <div className="privacy-modal-buttons">
                        <ButtonComponent onClick={onReject}>Reject All</ButtonComponent>
                        <ButtonComponent onClick={onConfirm}>Confirm My Choices</ButtonComponent>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default PrivacyModalComponent;
