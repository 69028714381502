import React from 'react';
import './Documentation.css';

function Documentation() {
  return (
    <div>
      Documentation
    </div>
  );
}

export default Documentation;
