import TennisInstructor from '../../../assets/icons/tennis-instructor.jpeg';
import USOpen from '../../../assets/icons/us-open-tennis.png'
import Scoring from '../../../assets/icons/scoring.jpeg'
import TennisOlympics from '../../../assets/icons/tennis-olympics.png'
import TennisCommunity from '../../../assets/icons/tennis-community.jpeg';

export const OTTACarouselItems = [
    {
      image: TennisInstructor,
      heading: 'Growing the Community',
      subheading: 'Attracting More Players, Fostering Connections',
      paragraph: 'When OTTA embraced SportsTalk, they witnessed a surge in community engagement. The platform\'s dynamic player interaction features facilitated seamless connections between table tennis enthusiasts. OTTA successfully attracted a wave of new players, creating a vibrant and diverse community united by their love for the sport.',
    },
    {
      image: USOpen,
      heading: 'Innovative Tournaments',
      subheading: 'Running Tournaments with Precision',
      paragraph: 'The OTTA team leveraged SportsTalk\'s versatile capabilities to organize and run tournaments within the platform. The integrated game score tracking system provided a streamlined and efficient way to reflect tournament results, ensuring accuracy and transparency in every match.',
    },
    {
      image: Scoring,
      heading: 'Automatic Rating Updates',
      subheading: 'Effortless Skill Recognition',
      paragraph: 'One of the key highlights of OTTA\'s experience with SportsTalk was the automatic updating of players\' ratings after each game. This feature not only recognized and rewarded skill but also motivated players to continually improve. The result was a more dynamic and competitive table tennis community, driven by a fair and accurate rating system.',
    },
    {
      image: TennisOlympics,
      heading: 'Real-Time Global Showcase',
      subheading: 'Bringing Table Tennis to the World',
      paragraph: 'With SportsTalk\'s live streaming functionality, OTTA elevated the visibility of their tournaments to a global audience. Table tennis enthusiasts from around the world tuned in to watch real-time matches, creating a sense of excitement and unity within the global table tennis community. The platform became a virtual arena where players showcased their skills, and fans celebrated the spirit of the game together.',
    },
    {
      image: TennisCommunity,
      heading: 'The Impact',
      subheading: 'Table Tennis Redefined, Community Strengthened',
      paragraph: 'The partnership between Ontario Table Tennis Association and SportsTalk has not only redefined how table tennis is played and enjoyed but has also strengthened the global table tennis community. More than just a platform, SportsTalk has become the heartbeat of OTTA\'s table tennis world—a place where players connect, compete, and celebrate the joy of the game.'
    }
  ];