import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function AccordionComponent({ title, details }) {
  return (
    <Accordion
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: 'white' }}/>}
        aria-controls="panel-content"
        id="panel-header"
        sx={{
          padding: '1rem 2rem',
          backgroundColor: 'var(--navy)',
          color: 'white',
        }}
      >
        <Typography
          sx={{
            fontWeight: '600',
            textTransform: 'uppercase',
            fontFamily: 'Poppins, sans-serif',
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: '2rem',
          color: 'var(--navy)',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Poppins, sans-serif',
          }}
        >
          {details}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}

export default AccordionComponent;
