import React from 'react';
import './SignUpUpdates.css';
import Megaphone from '../../assets/icons/megaphone.png';

const SignUpUpdates = () => {
  return (
    <div className="signup-updates">
      
      <div className="signup-updates-hero-section">
        <img src={Megaphone} alt="Megaphone" className="signup-updates-hero-section-megaphone-image" />
        <div className="signup-updates-hero-section-hero-text">
          <h1>Get the latest news from EdgeJumps</h1>
          <h3>Just fill out the form below and we'll be landing in your inbox in no time.</h3>
        </div>
      </div>

      <div className="signup-updates-content-section">"
        <div className="signup-updates-content-section-left-column">
          <h2>The best way to stay up to date with everything EdgeJumps</h2>
          <p>Just fill in the form and we'll start sending you:</p>
          <ul>
            <li>Monthly newsletters packed with how-to articles, sports news, videos and more</li>
            <li>Invitations to thrilling sports events across the globe</li>
            <li>Early notice of all our new releases and product announcements.</li>
          </ul>
          <p>Make sure you tick the box to opt in for communications so we have permission to email you!</p>
          <p>And, of course, you can unsubscribe at any time.</p>
        </div>

        <div className="signup-updates-content-section-right-column">
          <iframe 
            src="https://cdn.forms-content-1.sg-form.com/5b83ebf2-9b89-11ee-8cc2-f6056fbe1c05"
            title="Signup Form"
            height="800"
            width="600"
            frameBorder={0}
          />
        </div>
      </div>
    </div>
  );
}

export default SignUpUpdates;
