import EventIcon from '@mui/icons-material/Event';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import CampaignIcon from '@mui/icons-material/Campaign';
import ScheduleIcon from '@mui/icons-material/Schedule';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CelebrationIcon from '@mui/icons-material/Celebration';

import Events from '../../../assets/icons/event.png';
import Spotlight from '../../../assets/icons/spotlight.png';
import Integration from '../../../assets/icons/integration.png';

import Channel from '../../../assets/icons/tutorial.png';

export const customizableFrontPageData = [
    {
      img: Events,
      heading: "Exciting Events Ahead",
      subheading: "Showcase Upcoming Highlights",
      text: "Easily feature upcoming games, tournaments, and events on your club's frontpage. Keep your members and fans in the loop with all the latest happenings.",
      cardsPerRow: 3
    },
    {
      img: Spotlight,
      heading: "Spotlight on Stars",
      subheading: "Celebrate Your Top Players",
      text: "Personalize your frontpage by highlighting the achievements and stories of your club's star players. Create a sense of pride and inspiration within your community.",
      cardsPerRow: 3
    },
    {
      img: Integration,
      heading: "Seamless Integration",
      subheading: "Connect Your Existing Website",
      text: "Effortlessly link your existing website to SportsTalk, creating a unified online presence. Make it easy for members and fans to access all your content in one place.",
      cardsPerRow: 3
    }
  ];  

  export const memberManagementData = [
    {
      title: "Welcoming New Players",
      details: "Automated welcome messages, easy addition to groups, and an introduction process for new players."
    },
    {
      title: "Organizing Events",
      details: "Tools for event creation, calendar integration, RSVP tracking, and member notifications."
    },
    {
      title: "Celebrating Achievements",
      details: "Feature for highlighting player achievements, sharing team successes, and archiving club history."
    },
    {
      title: "Seamless Communication and Collaboration",
      details: "Communication tools like forums, chats, direct messages, and collaboration features for teams."
    },
    {
      title: "Member Management Tools",
      details: "Roster management, payment tracking, and analytics tools for player performance assessment."
    }
  ];

  export const tournamentSteps = [
    {
      icon: <EventIcon />,
      label: "Stage 1",
      title: "Planning",
      description: "Initiate your tournament with comprehensive planning tools.",
    },
    {
      icon: <HowToRegIcon />,
      label: "Stage 2",
      title: "Registration",
      description: "Open registration for teams or individual participants.",
    },
    {
      icon: <CampaignIcon />,
      label: "Stage 3",
      title: "Promoting",
      description: "Spread the word about your tournament.",
    },
    {
      icon: <ScheduleIcon />,
      label: "Stage 4",
      title: "Scheduling",
      description: "Efficiently schedule matches and events.",
    },
    {
      icon: <PlayCircleOutlineIcon />,
      label: "Stage 5",
      title: "Executing",
      description: "Run your tournament with ease.",
    },
    {
      icon: <AssessmentIcon />,
      label: "Stage 6",
      title: "Tracking Results",
      description: "Accurately track and display results as the tournament progresses.",
    },
    {
      icon: <CelebrationIcon />,
      label: "Stage 7",
      title: "Celebrating and Sharing",
      description: "Celebrate the winners and share the success of your tournament.",
    }
  ];
  

export const channelsData = [
    {
      title: 'Channel 1',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      image: Channel, 
    },
    {
      title: 'Channel 2',
      description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      image: Channel, 
    },
    {
      title: 'Channel 3',
      description: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      image: Channel, 
    },
    {
      title: 'Channel 4',
      description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
      image: Channel, 
    },
    {
      title: 'Channel 5',
      description: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      image: Channel, 
    },
    {
      title: 'Channel 6',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      image: Channel, 
    },
  ];
  