import React, { useState } from 'react';
import { Stepper, Step, StepLabel, Button, Typography, Box } from '@mui/material';

function StepperComponent({ steps }) {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const isLastStep = activeStep === steps.length;
  const currentStep = isLastStep ? steps.length - 1 : activeStep;

  function CustomStepIcon(props) {
    const { completed, icon } = props;
  
    return (
      <Box
        sx={{
          color: completed ? '#fff' : 'var(--navy)',
          backgroundColor: completed ? 'var(--navy)' : 'var(--primary-orange)',
          zIndex: 1,
          width: 40,
          height: 40,
          display: 'flex',
          borderRadius: '50%',
          justifyContent: 'center',
          alignItems: 'center',
          border: '2px solid var(--navy)',
        }}
      >
        {icon}
      </Box>
    );
  }

  return (
    <div>
      <Stepper activeStep={activeStep} orientation="horizontal">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              StepIconComponent={(props) => <CustomStepIcon {...props} icon={React.createElement(step.icon)} />}
            >
              <Typography
                variant="h2"
                sx={{ 
                  fontFamily: 'Poppins, sans-serif', 
                  fontSize: '1.2rem', 
                  fontWeight: '600',
                  ml: '1rem', 
                }}
              >
                {step.label}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {!isLastStep && (
          <Typography sx={{ mt: 3, mb: 1, ml: 9, fontFamily: 'Poppins, sans-serif' }}>{steps[currentStep].description}</Typography>
        )}
        {!isLastStep && (
          <>
            <Button
              variant="outlined"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ 
                mt: 1, 
                mr: 1, 
                ml: 9, 
                fontFamily: 'Poppins, sans-serif',
                color: 'rgba(7, 11, 34, 0.7)',
                fontWeight: '600',
                border: '2px solid rgba(7, 11, 34, 0.7)',
                '&:hover': {
                  color: 'var(--navy)',
                  fontWeight: '600',
                  border: '2px solid var(--navy)',
                  backgroundColor: 'rgba(7, 11, 34, 0.1)',
                }
               }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleNext}
              sx={{ 
                mt: 1, 
                mr: 1, 
                ml: 1, 
                fontFamily: 'Poppins, sans-serif',
                backgroundColor: 'rgba(7, 11, 34, 0.7)',
                color: 'rgba(255, 255, 255, 0.7)',
                '&:hover': {
                  backgroundColor: 'var(--navy)',
                  color: 'white',
                }
              }}
            >
              Next
            </Button>
          </>
        )}
        {isLastStep && (
          <div>
            <Typography sx={{ mt: 3, mb: 1, ml: 9, fontFamily: 'Poppins, sans-serif'}}>All steps completed - you&apos;re finished integrating the SportsTalk API!</Typography>
            <Button 
              variant="outlined" 
              onClick={handleReset} 
              sx={{ 
                mt: 1, 
                mr: 1, 
                ml: 9,
                fontFamily: 'Poppins, sans-serif',
                color: 'rgba(7, 11, 34, 0.7)',
                fontWeight: '600',
                border: '2px solid rgba(7, 11, 34, 0.7)',
                '&:hover': {
                  color: 'var(--navy)',
                  fontWeight: '600',
                  border: '2px solid var(--navy)',
                  backgroundColor: 'rgba(7, 11, 34, 0.1)',
                }
              }}
            >
              Reset
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default StepperComponent;
