import React from 'react';
import './Comparisons.css';

function Comparisons() {
  return (
    <div>
      Comparisons
    </div>
  );
}

export default Comparisons;
