import React, { createContext, useState, useContext } from 'react';

const NotificationContext = createContext();

export const useNotificationContext = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [isNotificationVisible, setIsNotificationVisible] = useState(true);

  const hideNotification = () => {
    setIsNotificationVisible(false);
  };

  return (
    <NotificationContext.Provider value={{ isNotificationVisible, hideNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};
