import Subscription from '../../../assets/icons/subscription.png';
import Customize from '../../../assets/icons/hand.png';
import Network from '../../../assets/icons/networking.png';
import USOpen from '../../../assets/icons/us-open-tennis.png';
import Community from '../../../assets/icons/tennis-community.jpeg'
import Instructor from '../../../assets/icons/tennis-instructor.jpeg'
import QRCode from '../../../assets/qr-codes/app-install-qr.png';

// import QRCodeComponent from '../../../components/molecule/QRCodeComponent/QRCodeComponent';
import CardComponent from '../../../components/molecule/CardComponent/CardComponent';
import CarouselComponent from '../../../components/molecule/CarouselComponent/CarouselComponent';
import AccordionComponent from '../../../components/molecule/AccordionComponent/AccordionComponent';

import { Box } from '@mui/material';

  // export const qrCodeUrl = 'https://www.barkandwhiskers.com/content/images/2023/08/samoyed-dogs.webp';

  export const profileCards = [
    {
      img: Subscription,
      heading: 'Set Up Your Profile',
      subheading: 'Showcase Your Skills',
      text: 'Highlight your sports achievements and interests.'
    },
    {
      img: Customize,
      heading: 'Customize Preferences',
      subheading: 'Personalize Your Experience',
      text: 'Choose sports and levels you’re interested in.'
    },
    {
      img: Network,
      heading: 'Connect and Engage',
      subheading: 'Join the Community',
      text: 'Find and connect with like-minded athletes.'
    }
  ];

  export const peerConnectCarouselItems = [
    {
      image: Instructor,
      heading: 'Find Local Players',
      subheading: 'Connect Locally',
      paragraph: 'Join local sports events and meet athletes in your area.'
    },
    {
      image: Community,
      heading: 'Join Groups',
      subheading: 'Interest-Based Communities',
      paragraph: 'Become part of groups that share your sports interests and passions.'
    },
    {
      image: USOpen,
      heading: 'Participate in Events',
      subheading: 'Stay Active',
      paragraph: 'Engage in community events and competitions.'
    }
  ];
  export const resourceAccordionItems = [
    {
      title: 'Training Tips',
      details: 'Explore expert advice and tips to enhance your training regimen.'
    },
    {
      title: 'Sports News',
      details: 'Stay updated with the latest news in the world of sports.'
    },
    {
      title: 'Event Calendar',
      details: 'Check out upcoming sports events and competitions.'
    }
  ];

export const steps = [
  {
    label: 'Download the App',
    content: (
      <Box>
        <div className="individual-download-app-qr-code">
          {/* <QRCodeComponent qrCodeUrl={qrCodeUrl} /> */}
          <img className="individual-download-app-qr-code-img" src={QRCode} alt="QR Code" />
          <div className="individual-download-app-qr-code-text">
            <h2>Join the SportsTalk Community Today!</h2>
            <p>Don't miss out on the ultimate sports experience. Scan the QR code now to download the SportsTalk app and start connecting with athletes and sports enthusiasts around the globe. Elevate your sports journey with personalized training tips, latest news, and exclusive community events. Your sports adventure awaits – download now!</p>
          </div>
        </div>
      </Box>
    ),
  },
  {
    label: 'Create Your Profile',
    content: (
      <Box>
        <div className="individual-create-profile-cards">
          {profileCards.map((card, index) => (
            <CardComponent
              key={index}
              img={card.img}
              heading={card.heading}
              subheading={card.subheading}
              text={card.text}
              cardsPerRow={3}
              canShare={false}
              canLearnMore={false}
            />
          ))}
        </div>
      </Box>
    ),
  },
  {
    label: 'Connect With Your Peers',
    content: (
      <Box>
        <div className="individual-peer-connect-carousel">
          <CarouselComponent items={peerConnectCarouselItems} />
        </div>
      </Box>
    ),
  },
  {
    label: 'Discover Resources',
    content: (
      <Box>
        <div className="individual-resources-accordion">
          {resourceAccordionItems.map((item, index) => (
            <AccordionComponent
              key={index}
              title={item.title}
              details={item.details}
            />
          ))}
        </div>
      </Box>
    ),
  },
  {
    label: 'Engage and Thrive',
    content: (
      <Box>
        <div className="individual-final-step">
          <p>
            Fuel your passion and boost your skills with the SportsTalk community. Get involved, share your experiences, and learn from others. Whether you're a beginner or a seasoned athlete, there's always something new to discover. Join us and be part of a community that's as passionate about sports as you are!
          </p>
        </div>
      </Box>
    )
  }
];
