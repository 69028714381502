import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './PostingLayout.css';

function PostingLayout({ subheader, header, description, children, hasBackButton = true }) {

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/posting');
  };

  const handleCTAClick = () => {
    navigate('/get-started');
  };

  return (
    <div className="posting-layout">
      <div className="posting-header">
        <div>
          {hasBackButton && (
            <Button 
              onClick={handleBackClick}
              sx={{ 
                textTransform: 'uppercase', 
                color: '#FFFFFF', 
                paddingLeft: '3rem',
                minWidth: 0,
                fontSize: '18px',
                fontWeight: 300,
                fontFamily: 'Poppins, sans-serif',
                '&:hover': { background: 'none' }
              }}
            >
              {"< BACK"}
            </Button>
          )}
          <h2>{subheader}</h2>
          <h1>{header}</h1>
          <p>{description}</p>
        </div>
      </div>
      <div className="posting-body">
        {children}
      </div>
      <div className="posting-footer-dark">
        <div className="posting-footer">
          <h1>What's Next <span className="footer-text-white">- Continuously Evolving for You</span></h1>
          <p>At SportsTalk, we are committed to pushing the boundaries of sports engagement. Stay tuned for more updates, features, and partnerships as we continue to evolve and create a platform that caters to the diverse needs and passions of the sports community.</p>
          <p>Join us in celebrating these exciting milestones and experience the future of sports engagement with SportsTalk! Whether you're a player, a fan, or both, there's always something new and thrilling happening in the world of SportsTalk.</p>
          <h2>Choose innovation, choose community, choose SportsTalk!</h2>
          <div className="cta-button-container">
            <h1>Try SportsTalk For Free Today!</h1>
            <Button
              onClick={handleCTAClick}
              className="cta-button"
              variant="contained"
            >
              Get Started
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostingLayout;
