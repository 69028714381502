import React from 'react';
import BottomBarComponent from '../../components/molecule/BottomBarComponent/BottomBarComponent';
import './ComingSoonLockdown.css';

const ComingSoonLockdown = () => {
  // const [email, setEmail] = useState('');

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log('Submitted email:', email);
  // };

  return (
    <div className="lockdown-container">
        <div className="lockdown-main-content">
            <h1>We Are Coming Soon</h1>
            <p>Sign up to be the first to know when we go live.</p>
            {/* <form onSubmit={handleSubmit}>
                <input 
                type="email" 
                placeholder="Enter your email" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                />
                <button type="submit">Sign Up</button>
            </form> */}
            <iframe 
              className="email-signup"
              title="email-signup-form"
              width="370"
              height="330"
              src="https://cdn.forms-content-1.sg-form.com/2f783a77-9933-11ee-bb38-02f575449293"
            />
        </div>
        <BottomBarComponent />
    </div>
  );
};

export default ComingSoonLockdown;
