import React, { useState } from 'react';
import './TopBarDropdownComponent.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'; 
import TopBarModalComponent from '../TopBarModalComponent/TopBarModalComponent';

const TopBarDropdownComponent = ({ label, links }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div
      className={`top-bar-dropdown-component ${isOpen ? '--open' : ''}`}
      onClick={handleToggle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {label}
      <ArrowDropDownIcon className={isOpen ? "dropdown-icon open" : "dropdown-icon"} />
      {isOpen && links && <TopBarModalComponent links={links} />}
    </div>
  );
};

export default TopBarDropdownComponent;
