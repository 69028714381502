import React from 'react';
import Button from '@mui/material/Button';
import './ButtonComponent.css';

const ButtonComponent = ({ children, variant, color, onClick, className, ...props }) => {
  return (
    <Button 
      variant={variant || 'contained'} 
      color={color || 'primary'} 
      onClick={onClick} 
      className={`${className && className} button-component`}
      sx={{
        fontFamily: 'Poppins, sans-serif',
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default ButtonComponent;
