import React from 'react';
import './BillboardLayout.css';
import ButtonComponent from '../../components/atom/ButtonComponent/ButtonComponent';

const BillboardLayout = ({ heading, paragraph, imageUrl, buttonText, onButtonClick }) => {
  return (
    <div className="billboard-layout">
      <div className="billboard-text-block">
        <div className="billboard-heading">{heading}</div> 
        <div className="billboard-paragraph">{paragraph}</div>
        {buttonText && onButtonClick && (
          <ButtonComponent onClick={onButtonClick}>
            {buttonText}
          </ButtonComponent>
        )}
      </div>
      <div className="billboard-image-container">
        <img src={imageUrl} alt="billboard" className="billboard-image" />
      </div>
    </div>
  );
};

export default BillboardLayout;
