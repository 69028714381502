import React from 'react';
import { useNavigate } from 'react-router-dom';
import './GetStarted.css'; 
import WebsiteIcon from '../../assets/icons/ux.png'
import BetaIcon from '../../assets/icons/ui-design.png'
import ButtonComponent from '../../components/atom/ButtonComponent/ButtonComponent';

const GetStarted = () => {
  const navigate = useNavigate();

  return (
    <div className="get-started">
      <div className="get-started-header">
        <div>
          <h2>Get Started With SportsTalk</h2>
          <h1>Discover the Power of SportsTalk <span className="heading-white"> - Your One-Stop Hub for Sports Excellence</span></h1>
          <p>SportsTalk is more than just a platform; it’s a dynamic ecosystem designed to foster connection, community, and excellence in the sports world. Whether you’re a club administrator, organization leader, or an individual player, SportsTalk is your partner in navigating the exciting world of sports.</p>
        </div>
      </div>
      
      <div className="get-started-body">

      <div className="user-type-section">
          <img src={WebsiteIcon} alt="Individual Players" className="left-image"/>
          <div className="right-text">
            <h1>SportsTalk Mobile App</h1>
            <h2>For Individual Players</h2>
            <p>
              Streamline your club's management and engagement with our Community Portal. This hub enables you to organize events, communicate with members, and expand your club's reach. It's an essential tool for fostering a strong and interactive sports club community.
            </p>
            <ButtonComponent onClick={() => navigate('/individual')}>Learn More</ButtonComponent>
          </div>
        </div>

        <div className="user-type-section">
        <div className="left-text">
            <h1>Community Portal</h1>
            <h2>For Club Users</h2>
            <p>
              The Organization Dashboard is a comprehensive tool for sports organization administrators. Manage teams, coordinate events, and gain valuable insights to drive your organization forward. Simplify your administrative tasks and focus on nurturing sporting talent.
            </p>
            <ButtonComponent onClick={() => navigate('/club')}>Learn More</ButtonComponent>
          </div>
          <img src={WebsiteIcon} alt="Club Users" className="right-image"/>
          
        </div>

        <div className="user-type-section">
          
          <img src={BetaIcon} alt="Organization Admins" className="left-image"/>
          <div className="right-text">
            <h1>Organization Dashboard</h1>
            <h2>For Sports Organization Administrators</h2>
            <p>
              The Organization Dashboard is a comprehensive tool for sports organization administrators. Manage teams, coordinate events, and gain valuable insights to drive your organization forward. Simplify your administrative tasks and focus on nurturing sporting talent.
             </p>
            <ButtonComponent onClick={() => navigate('/sports-organization')}>Learn More</ButtonComponent>
          </div>
        </div>

        
      </div>
    </div>
  );
}

export default GetStarted;
