import Innovation from '../../assets/icons/integration.png';
import Network from '../../assets/icons/networking.png';
import User from '../../assets/icons/hand.png';

export const CardContent = [
  {
    img: Innovation, 
    heading: "Innovation at Heart",
    subheading: "Transforming Sports",
    text: "We are not just observers of the sports world; we are innovators. Our team is driven by a relentless pursuit of creating innovative solutions that elevate the sports experience.",
    canShare: false,
    canLearnMore: false
  },
  {
    img: Network, 
    heading: "Community-Centric Approach",
    subheading: "Fostering Connections",
    text: "Central to our ethos is the belief in the power of community. We aim to foster connections, build networks, and create a sense of belonging among sports enthusiasts.",
    canShare: false,
    canLearnMore: false
  },
  {
    img: User, 
    heading: "User-First Philosophy",
    subheading: "Prioritizing Your Needs",
    text: "Your experience matters. Our user-first philosophy guides everything we do. From app development to customer support, we prioritize your needs and feedback.",
    canShare: false,
    canLearnMore: false
  }
];
