import React from 'react';
import './CarouselItemComponent.css'

const CarouselItemComponent = ({ image, heading, subheading, paragraph }) => {
  return (
    <div className="carousel-item">
      <div className="carousel-item-content">
        <img src={image} alt={heading} />
        <div className="carousel-item-text">
          <h1>{heading}</h1>
          <h2>{subheading}</h2>
          <p>{paragraph}</p>
        </div>
      </div>
    </div>
  );
};

export default CarouselItemComponent;
