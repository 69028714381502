import React from 'react';
import './CompanyNews.css';
import PostingLayout from '../../layouts/PostingLayout/PostingLayout';

function Blogs() {

  return (
    <PostingLayout
      hasBackButton={false}
      subheader="EdgeJumps News"
      header="Your latest updates from our developers"
      description="Stay informed with the latest news and updates directly from the EdgeJumps development team. Explore insights, behind-the-scenes developments, and exclusive updates about our products and services."
    >
      haha hehe
    </PostingLayout>
  );
}

export default Blogs;
