import React from 'react';
import './Events.css';
import CardComponent from '../../../components/molecule/CardComponent/CardComponent';
import PostingLayout from '../../../layouts/PostingLayout/PostingLayout';
import { eventItems } from './constants';

function Events() {

  return (
    <PostingLayout
      hasBackButton={false}
      subheader="Events"
      header="Experience the Thrill with SportsTalk Events"
      description="Dive into the heart of the action with SportsTalk's Upcoming Events, your gateway to adrenaline-pumping competitions and community-building gatherings. Celebrate skill, spirit, and sportsmanship in a dynamic world of sports, where every event is not just a game, but a journey into unbreakable bonds and unforgettable excitement!"
    >
      <div className="events">
        {eventItems.map((item, index) => (
          <CardComponent
            key={index} 
            img={item.img}
            heading={item.heading}
            subheading={item.subheading}
            text={item.text}
            canLearnMore={false}
            cardsPerRow={3}
          />
        ))}
      </div>
    </PostingLayout>
  );
}

export default Events;
