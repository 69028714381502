import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styles } from './styles';

function CardComponent({ 
  img, heading, subheading, text, 
  cardsPerRow = 2, canShare = true, 
  canLearnMore = true, onLearnMore 
}) {
  const cardStyle = cardsPerRow === 3 ? styles.threePerRow : styles.twoPerRow;

  return (
    <Card sx={{ ...cardStyle, maxWidth: 'none', padding: '20px', borderRadius: '30px', display: 'flex', flexDirection: 'column' }}>
      <CardMedia
        component="img"
        alt={heading}
        height="300px"
        image={img}
      />
      <CardContent style={{ flex: '1' }}>
        <Typography gutterBottom variant={cardsPerRow === 3 ? "h5" : "h4"} component="div" sx={{ fontWeight: '700', paddingTop: '15px', fontFamily: 'Poppins, sans-serif' }}>
          {heading}
        </Typography>
        <Typography gutterBottom variant={cardsPerRow === 3 ? "subtitle2" : "subtitle1"}  component="div" sx={{ fontStyle: 'italic', fontFamily: 'Poppins, sans-serif' }}>
          {subheading}
        </Typography>
        <Typography variant={cardsPerRow === 3 ? "body2" : "body1"}  color="text.secondary" sx={{ fontFamily: 'Poppins, sans-serif' }}>
          {text}
        </Typography>
        </CardContent>
      <div style={{ marginTop: 'auto' }}>
        <CardActions>
          {canShare && <Button size="large">Share</Button>}
          {canLearnMore && onLearnMore && (
            <Button size="large" onClick={onLearnMore}>Learn More</Button>
          )}
        </CardActions>
      </div>
    </Card>
  );
}

export default CardComponent;