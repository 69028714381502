import React from 'react';
import './Customers.css';
import WhySportsTalkLayout from '../../../layouts/WhySportsTalkLayout/WhySportsTalkLayout';
import CarouselComponent from '../../../components/molecule/CarouselComponent/CarouselComponent';
import { OTTACarouselItems } from './constants';

function Customers() {
  const header = "Uniting Communities, One Conversation at a Time.";
  const subheader = "Partners";
  const description = "With organizations like the Ontario Table Tennis Association on board, something about SportsTalk clearly works for our partners. Read how our customers have leveraged SportsTalk technology and professional services to effectively impact their organization's success!";

  const body = (
    <>
      <div className="customers">
        <div className="customers-heading"><h2>Celebrating Success Across Communities with SportsTalk ...</h2></div>
        <div className="customers-OTTA">
          <h1>Ontario Table Tennis Association</h1>
          <h2>Empowering Table Tennis, Building a Global Community.</h2>
          <p>
            At SportsTalk, we take pride in the success stories of our partners, and the Ontario Table Tennis Association (OTTA) stands out as a shining example of the transformative power of our platform. Let's delve into the remarkable journey of OTTA and how SportsTalk became a game-changer for their table tennis community.
          </p>
          <CarouselComponent items={OTTACarouselItems} />
        </div>
      </div>
    </>
  );

  return (
    <WhySportsTalkLayout
      header={header}
      subheader={subheader}
      description={description}
      children={body}
      footerHeader={<h1>Elevate Your Community!</h1>}
      footerDescription={<p>Join us in celebrating the success of Ontario Table Tennis Association on SportsTalk, where every match is an opportunity to inspire and be inspired, and where the love for sports knows no boundaries. Choose SportsTalk and elevate your sports community to new heights.</p>}
      footerSubheader={<h2>Join the Movement With SportsTalk Today!</h2>}
    />
  );
}

export default Customers;
