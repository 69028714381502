import React from 'react';
import CardComponent from '../../../components/molecule/CardComponent/CardComponent';
import AccordionComponent from '../../../components/molecule/AccordionComponent/AccordionComponent';
import TimelineComponent from '../../../components/molecule/TimelineComponent/TimelineComponent';
import ProductLayout from '../../../layouts/ProductLayout/ProductLayout';
import { customizableFrontPageData, memberManagementData, tournamentSteps, channelsData } from './constants'; 
import './Club.css';

function Club() {
  return (
    <ProductLayout
      subheader="Community Portal"
      header="Empowering Your Club Experience with SportsTalk"
      description="As a Club User on SportsTalk, you're at the heart of a dynamic and thriving sports community. Discover a suite of features designed to empower club administrators and members alike!"
      footerHeader={<h1>What's Next for Your Community <span className="footer-text-white">- Elevate, Connect, and Thrive</span></h1>}
      footerDescription={[
        <p>SportsTalk is not just a platform; it's a dynamic community that celebrates the spirit of sportsmanship and camaraderie. As a community portal user, you have the tools to elevate your club experience, connect with fellow members, and thrive in the world of sports.</p>,
        <p>Join us in creating a vibrant sports community on SportsTalk. Whether you're a seasoned community portal administrator or a passionate member, there's a world of possibilities waiting for you.</p>
      ]}
      footerSubheader={<h2>Choose connection, choose community, choose SportsTalk!</h2>}
    >
      <div className="club-page">
        <div className='customizable-frontpage'>
          <h1>Take the Stage with our Customizable Frontpage</h1>
          <div className="customizable-frontpage-cards">
            {customizableFrontPageData.map((card, index) => (
              <CardComponent
                key={index}
                img={card.img}
                heading={card.heading}
                subheading={card.subheading}
                text={card.text}
                canLearnMore={false}
                canShare={false}
                cardsPerRow={card.cardsPerRow}
              />
            ))}
          </div>
          <div className="member-management">
            <h1>Member Management Made Easy</h1>
            <div className="member-management-accordion">
              {memberManagementData.map((item, index) => (
                <AccordionComponent
                  key={index}
                  title={item.title}
                  details={item.details}
                />
              ))}
            </div>
          </div>
          <div className="tournament-org-result-tracking">
            <h1>Tournament Organization and Result Tracking</h1>
            <TimelineComponent steps={tournamentSteps} />
          </div>
        </div>
        <div className="channels">
          <div className="channels-header">
            <h1>Dive into Club Channels</h1>
            <p>
              Community members can immerse themselves in the vibrant community by accessing and exploring community channels. Stay updated on the latest announcements, discussions, and events specific to your club. From sharing game strategies to celebrating victories, community channels are the heartbeat of your community, fostering communication and engagement among members.
            </p>
            <h3>Check out some of our featured channels...</h3>
          </div>
          <div className="channels-grid-container">
            {channelsData.map((channel, index) => (
              <div key={index} className="channels-grid-item">
                <img src={channel.image} alt={channel.title} />
                <div className="channels-description-overlay">
                  <h3>{channel.title}</h3>
                  <p>{channel.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </ProductLayout>
  );
}

export default Club;
