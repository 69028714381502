import React, { createContext, useState, useContext } from 'react';

const SearchBarContext = createContext();

export const useSearchBarContext = () => useContext(SearchBarContext);

export const SearchBarProvider = ({ children }) => {
  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);

  return (
    <SearchBarContext.Provider value={{ isSearchBarOpen, setIsSearchBarOpen }}>
      {children}
    </SearchBarContext.Provider>
  );
};
