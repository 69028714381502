import React, { useState, useEffect } from 'react';
import './Individual.css';
import ProductLayout from '../../../layouts/ProductLayout/ProductLayout';
import { Stepper, Step, StepLabel, StepContent, StepIcon, Typography } from '@mui/material';
import {steps} from './constants';

function Individual() {
  const [activeSteps, setActiveSteps] = useState([]);

  useEffect(() => {
    setActiveSteps(steps.map((_, index) => index));
  }, []);

  const CustomStepIcon = (props) => {
    return (
      <StepIcon
        {...props}
        sx={{ 
          fontSize: '2.6rem',
          fontFamily: 'Poppins, sans-serif',
          '&.MuiStepIcon-root .MuiStepIcon-text': {
            fill: 'white',
            fontWeight: '600',
          },
          '&.MuiStepIcon-root': {
            color: 'var(--navy)',
            backgroundColor: 'var(--navy)',
            borderRadius: '50%',
            border: '2px solid var(--navy)',
          },
        }} 
      />
    );
  };

  return (
    <ProductLayout
      subheader="Mobile App"
      header="Discover Resources at Your Fingertips"
      description="SportsTalk is more than just a platform; it's your ultimate resource hub. From tips and tricks to training guides and the latest sports news, find a wealth of resources that cater to your sports interests. Stay informed, enhance your skills, and immerse yourself in a community that shares your dedication and enthusiasm for the game."
      footerHeader={<h1>What Awaits You <span className="footer-text-white">- Unlease the Player in You</span></h1>}
      footerDescription={[
        <p>SportsTalk is designed to amplify your sports journey, whether you're seeking connections, resources, or just a good time playing the game you love.</p>,
        <p>Join us in creating a global community of passionate players!</p>
      ]}
      footerSubheader={<h2>Choose connection, choose resources, choose SportsTalk!</h2>}
    >
      <div className="individual">
        <Stepper orientation="vertical" nonLinear>
          {steps.map((step, index) => (
            <Step key={step.label} active={activeSteps.includes(index)} completed={false}>
              <StepLabel StepIconComponent={CustomStepIcon}>
                <Typography
                  variant="h1"
                  sx={{
                    color: 'var(--navy)',
                    fontWeight: '700',
                    fontFamily: 'Poppins, sans-serif',
                  }}
                >
                  {step.label}
                </Typography>
              </StepLabel>
              <StepContent>
                {step.content}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </div>
    </ProductLayout>
  );
}

export default Individual;
