import React from 'react';
import { Link } from 'react-router-dom'; 
import './TopBarModalComponent.css';

const TopBarModalComponent = ({ links, isSearch = false }) => {
  return (
    <div className="top-bar-modal">
      {links.map((link, index) => (
        <Link key={index} to={link.url} className="top-bar-modal-link-item">
          <div className="top-bar-modal-link">
            {link.label}
          </div>
          {link.description && (
            <p className="top-bar-modal-link-description">{link.description}</p>
          )}
        </Link>
      ))}
    </div>
  );
};

export default TopBarModalComponent;
